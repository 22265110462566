import './public-path';	
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.css'
import './assets/css/font.css'
import axios from 'axios'
import fastclick from 'fastclick'
// // 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'
// import Charts from '@jiaminghi/charts'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import "echarts-gl";
// Vue.use(dataV)
import moment from "moment";
Vue.prototype.$moment = moment
moment.locale('zh-cn')




// import cookies from 'vue-cookies'
// Vue.prototype.$cookies = cookies;

// import $ from 'jquery'
// 注册组件后即可使用
Vue.component('v-chart', echarts)
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false
fastclick.attach(document.body);

// Vue.aa= 'http://47.113.111.247:80'
// 判断是否是qiankun环境，一定要写在最上面
if (window.__POWERED_BY_QIANKUN__) {
    __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
  }
  
  // 设置全局变量，用于保存或销毁Vue实例
  let instance = null 
  
  //不是在qiankun环境下的话，独立运行
  if (!window.__POWERED_BY_QIANKUN__) {
    console.log('独立运行')
    render()
  }
  
  function render(props) {
    const { container } = props || {}
    instance = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount(container ? container.querySelector('#app') : '#app') // 用于限定当前上下文下的#app，防止与主应用中的#app冲突
  }
  
  // 导出相应的生命周期钩子
  /**
   * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
   * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
   */
  export async function bootstrap() {
    console.log('qiankun-bootstrap')
  }
  
  /**
   * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
   */
  export async function mount(props) {
    console.log('qiankun-mount参数', props)
    render(props)  // 从qiankun启动
    // ReactDOM.render(<App />, props.container ? props.container.querySelector('#root') : document.getElementById('root'))
  }
  
  /**
   * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
   */
  export async function unmount(props) {
    instance.$destroy() // 销毁子应用实例
    instance= null
    // ReactDOM.unmountComponentAtNode(props.container ? props.container.querySelector('#root') : document.getElementById('root'))
  }
  
  /**
   * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
   */
  export async function update(props) {
    console.log('update props', props)
    render(props)  // 从qiankun启动
  }
// new Vue({
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')
