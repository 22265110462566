<template>
  <div id="app" ref="app">
    <div class="appcor">
      <!--  <keep-alive>
    <router-view />
      </keep-alive> -->
      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view> -->
      <router-view />
    </div>
  </div>
</template>
<script>
import { Form } from 'element-ui'
export default {
  data () {
    return {
      keyboards: ''
    }
  },
  components: {
    // Keyboard
  },
  watch: {
      $route: function (to, from) {
         document.getElementById('app').scrollTop = 0;
      },
  },
  mounted () {
  },
  methods: {
    GetKeyVal (val) {
      this.keyboards = val
    }
  }
}
</script>
<style lang="less" >
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#app {

 // background: url('assets/img/bg.jpg') no-repeat center center;
  overflow-y: auto;
}
.appcor{
  width:100%;
  height:100%;
  // background: url('assets/img/21.png') no-repeat center center;
}
.dv-scroll-board .header{
   height:28px!important;
    line-height:28px!important;
    color:rgba(0,195,250,1);
    background:rgba(3,94,255,.55)!important;
    .header-item{
      padding:0!important;
       height:28px!important;
    line-height:28px!important;
    }
}
.dv-scroll-board .rows{
  .row-item{
    background: rgba(3, 94, 255, 0.2)!important;
    border: 1px rgba(10, 67, 188, 0.56) solid!important;
  }
}
.dv-water-pond-level text{
  font-weight:lighter!important;
}
body .el-table th.gutter {
  display: table-cell !important;
}
.dv-capsule-chart .label-column{
  font-size:15px!important;
  color:#cff0ff!important;
}
 .dv-scroll-board{
    .header-item{
       font-size:16px;
       color:#40c7ff;
    }
    .row-item{
      border-top:1px #001b7b solid;
      .ceil{
        font-size:15px;
        color:#cff0ff;
        .index{
           font-size:14px;
           width:35px;
            background-image: linear-gradient(to bottom, #e56639, #feb018)!important;
            border: 1px solid #e56639 !important;
        }
      }
      .ceil:nth-of-type(3){
        color:#cff0ff;
        font-weight:100;
        height:100%;
        background:rgba(0,27,123,.2);
      }
      .ceil:nth-of-type(4){
        color:#30ffff;
        font-weight:100;
         font-size:18px;
          background:#0046ca;
      }
    }
   }
 .dv-scroll-ranking-board{
    .ranking-info{

        .rank{
          color: #a8e0ff!important;
            font-size: 16px;
        }
        .info-name{
            padding: 0 10px;
           white-space: nowrap;
           width:70%;
           overflow:hidden;
           text-overflow: ellipsis;
           vertical-align: middle;
           font-size:15px;
           color:rgba(255,255,255,.8)
        }
        .larger{
          font-size:16px;
          margin-left:20px;
          color:rgba(255,255,255,.8)!important;
        }
        .ranking-value{
          font-size:18px;
          color:#02afff!important;
        }
    }
    .ranking-column{
      background: #010170;
      border:none!important;
      .inside-column{
        // background-color: #13fbf6!important;
        height:10px!important;
        background: linear-gradient(to right, #0336ff, #0090ff,#00deff)!important;
      }
    }
 }
body .el-table colgroup.gutter {
  display: table-cell !important;
}
 .el-select-dropdown{
    background:#0072ff!important;
    border-color:#0072ff!important;
    color:#fff!important;
    .el-popper[x-placement^=bottom] .popper__arrow{
      border-bottom-color: #0e6de9;
    }
    .el-select-dropdown__item{
      color:#fff;
    }
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
   background-color: #01aaff!important;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
   background-color: #01aaff!important;
   color:#fff!important;
}
.el-popper[x-placement^=bottom] .popper__arrow::after{
   top:0!important;
   border-bottom-color:#0072ff!important;
}
.el-popper[x-placement^=bottom] .popper__arrow{
   border-bottom-color:#0072ff!important;
}
    .popperClass.el-picker-panel{
        width:200px;
        background-color: #00c2ff!important;
        color: #ffffff!important;
        border-color: #0088ff !important;
        font-size: 16px!important;
        background-image: linear-gradient(to bottom, #0c2883, #0050e9)!important;
    .el-date-table th{
       color:#fff!important;
    }
    .el-picker-panel__icon-btn{
       color:#fff!important;
    }
    .el-date-table th{
      border-bottom: dashed 1px #0050e9;
    }
    .el-date-range-picker__content.is-left{
      border-right: 1px solid #0050e9;
    }
    .el-date-table td.in-range div, .el-date-table td.in-range div:hover, .el-date-table.is-week-mode .el-date-table__row.current div, .el-date-table.is-week-mode .el-date-table__row:hover div {
          background-color: #F2F6FC;
          color: #333;
    }
    .el-picker-panel [slot=sidebar], .el-picker-panel__sidebar{
        background-image: linear-gradient(to bottom, #0c2883, #0050e9)!important;
        color: #ffffff;
        border-right-color:#0050e9;
        }
        .el-picker-panel__shortcut{
          color:#fff;
        }
  }
  .popperClass.el-popper[x-placement^=bottom] .popper__arrow::after{
      border-bottom-color: #0e6de9!important;
    }
    .popperClass.el-popper[x-placement^=bottom] .popper__arrow{
      border-bottom-color: #0e6de9;
    }
     #nprogress{
       background:rgba(0,0,0,0.1)!important;
       width:100%;
       height:100vh;
       position: absolute;
       top:0;
       left:0;
       z-index:99999999999999;
       pointer-events:auto!important;
     }
    #nprogress .spinner{
      top:48%!important;
      right:48%!important;
      z-index:999999999!important;
    }
    #nprogress .spinner-icon{
      width:50px!important;
      height:50px!important;
    }
    .el-picker-panel{
        background-color: #00c2ff!important;
        color: #ffffff!important;
        border-color: #0088ff !important;
        font-size: 16px!important;
        background-image: linear-gradient(to bottom, #0c2883, #0050e9)!important;
    }
    .el-date-picker__header-label{
        color: #ffffff!important;
    }
    .el-date-table th{
       color: #ffffff!important;
       border-bottom:1px #0c2883 dashed!important;
    }
    .el-select-dropdown{
         background-color: #00c2ff!important;
        color: #ffffff!important;
        border-color: #0088ff !important;
        font-size: 16px!important;
        background-image: linear-gradient(to bottom, #0c2883, #0050e9)!important;
    }
    .el-select-dropdown__item.selected{
      color:#fff!important;
    }
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
      background-color: #0050e9 !important;
    }
    .el-picker-panel__icon-btn{
      color:#ffffff!important;
    }
    .el-date-picker__header--bordered{
      border-bottom: 1px #264fd5 dashed!important;
    }
    .el-year-table td .cell{
      color:#ffffff!important;
      font-size:14px;
    }
    .el-month-table td .cell{
      color:#ffffff!important;
      font-size:14px;
    }
    .el-menu--popup{
        background: #004bb2;
        padding:0!important;
      }
</style>
